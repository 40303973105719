<template>
  <div style="border: 1px solid #ccc;">
    <Toolbar
      style="border-bottom: 1px solid #ccc"
      :editor="editor"
      :default-config="toolbarConfig"
      :mode="mode"
    />
    <Editor
      v-model="html"
      style="height: 500px; overflow-y: hidden;"
      :default-config="editorConfig"
      :mode="mode"
      @onCreated="onCreated"
      @onChange="onChange"
    />
  </div>
</template>

<script>
import { Editor, Toolbar } from '@wangeditor/editor-for-vue'
import { DomEditor } from '@wangeditor/editor'
export default {
  name: 'ForumPosts',
  components: { Editor, Toolbar },
  props: {
    htmls: {
      type: String,
      default: () => {}
    }
  },
  data() {
    return {
      editor: null,
      html: '',
      text: '',
      toolbarConfig: { excludeKeys: ['uploadImage'] },
      editorConfig: { placeholder: '请输入内容...暂时不支持上传本地图片, 可以先上传到云盘等云端, 填写URL地址插入图片' },
      mode: 'default' // or 'simple'
    }
  },
  created() {
    this.html = this.htmls
  },
  beforeDestroy() {
    const editor = this.editor
    if (editor == null) return
    editor.destroy() // 组件销毁时，及时销毁编辑器
  },
  methods: {
    onCreated(editor) {
      this.editor = Object.seal(editor) // 一定要用 Object.seal() ，否则会报错
    },
    onChange() {
      // console.log(this.editor.children)
      DomEditor.getToolbar(this.editor)
      this.text = this.editor.getText()
      // console.log(toolbar.getConfig())
      // console.log(this.text)
    }
  }
}
</script>

<style src="@wangeditor/editor/dist/css/style.css"></style>
