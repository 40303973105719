<template>
  <div class="big-box">
    <div class="top">
      <span><el-input v-model="title" placeholder="标题" /></span>
      <span><el-input v-model="tags" placeholder="标签" /></span>
      <el-button @click="submit">提交</el-button>
    </div>
    <div v-if="type === 'Markdown'">
      <mavon-editor class="mavon-editor" @change="MDchange" />
    </div>
    <div v-else>
      <forum-posts ref="childData" :htmls="html" class="forum-posts" />
    </div>
    <!-- <div class="markdown-body" v-html="Article.Content">{{ Article.Content }}</div> -->
  </div>
</template>

<script>
import ForumPosts from '@/components/ForumPosts/index.vue'
export default {
  components: { ForumPosts },
  data: () => ({
    type: '',
    html: '',
    title: '',
    tags: '',
    MDValue: '',
    MDHtml: ''
  }),
  created() {
    this.type = this.$route.query.type || 'Markdown'
  },
  methods: {
    MDchange(value, render) {
      this.MDValue = value
      this.MDHtml = render
    },
    async submit() {
      if (this.$route.query.type === 'Normal') {
        const content = this.$refs['childData'].html
        let text = this.$refs['childData'].text
        if (!this.title || !this.tags || !text) {
          this.$message({
            message: '请先输入内容',
            type: 'warning'
          })
          return
        }
        if (text.length > 100) text = text.substring(0, 99)
        text = text.replace(/<\/?.+?>/g, '')
        text = text.replace(/[\r\n]/g, ' ')

        const data = {
          title: this.title,
          tags: this.tags,
          content,
          text
        }

        const { code = false, results = [] } = await this.$store.dispatch('forum/createForum', data)
        if (code === 200) {
          console.log(results)
          this.$message({
            type: 'success',
            message: '帖子发布成功'
          })
          this.$router.push({ path: '/' })
        } else {
          this.$message.error('帖子发布失败,请稍后重试')
        }
      } else {
        if (!this.title || !this.tags || !this.MDValue) {
          this.$message({
            message: '请先输入内容',
            type: 'warning'
          })
          return
        }
        let text = this.MDValue
        if (text.length > 100) text = text.substring(0, 99)
        text = text.replace(/<\/?.+?>/g, '')
        text = text.replace(/[\r\n]/g, ' ')

        const data = {
          title: this.title,
          tags: this.tags,
          content: this.MDHtml,
          text
        }

        const { code = false, results = [] } = await this.$store.dispatch('forum/createForum', data)
        if (code === 200) {
          console.log(results)
          this.$message({
            type: 'success',
            message: '帖子发布成功'
          })
          this.$router.push({ path: '/' })
        } else {
          this.$message.error('帖子发布失败,请稍后重试')
        }
      }
    }
  }
}
</script>

<style scoped>
/* @import "../../assets/CSS/common.css"; */
@import '../../assets/CSS/mavonEditor.css';
.big-box {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 20px 0;
}
.content {
  width: 1200px;
}
.mavon-editor,
.forum-posts {
  width: 1200px;
  height: calc(100vh - 160px);
}
.forum-posts >>> div[data-w-e-textarea="true"] {
  height: calc(100% - 81px) !important;
}
.top {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 1200px;
  border: 1px solid rgb(204, 204, 204);
  border-bottom: none;
  padding: 5px 10px;
  background-color: #fff;
}
.top span {
  width: 40%;
}
input {
  width: 80%;
  border: none;
  border-bottom: 1px solid rgb(204, 204, 204);
  outline: none;
  padding-left: 10px;
  color: black;
}
</style>
